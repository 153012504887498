@import './app-variables.scss';

.action-cell {
  border-left: 2px outset !important;
}

.ag-theme-material {
  -webkit-font-smoothing: antialiased;
  background-color: $brand-color-3;
  color: $foundational-color-12;
  font-family: Roboto,sans-serif;
  font-weight: 400;
  line-height: 1;
}

.xpo-GridBar {
  background-color: $brand-color-3!important;
  & .xpo-FilterBar {
    &-filtersWrapper {
      padding-right: 10px;
      border-right: 1px solid $foundational-color-16;
      height: 40px;
      & > [class*="xpo-"] {
        margin: 0px 0 0px 8px!important;
        padding-top: 4px;
        .xpo-FilterChip-dropdownTrigger {
          height: 27px;
        }
        .xpo-QuickSearchFilter-formField {
          border:none;
        }
      }
    }
    & xpo-board-actions {
      border-right: 1px solid $foundational-color-16;
    }
    border-right: 1px solid $foundational-color-16;
  }
}
.xpo-Board-actionsWrapper {
  & > [class*="xpo-"] {
    height: 40px!important;
    border-radius: 0px;
    padding: 12px;
    width: 43px;
    border-left: 1px solid $foundational-color-16;
    & .mat-button {
      min-width: auto !important;
      padding: 0px;
    }
  }
  .mat-button-wrapper {
    font-size: 0px;
  }
}

.xpo-AgGrid.ag-theme-material {
  font-size: .875rem!important;
  .ag-header-cell,
  .ag-cell {
    border-right: 1px solid $foundational-color-4 !important;
    height: 100%
  }
  .ag-header {
    background-color: $foundational-color-11;
    color: $foundational-color-12;
    font-weight: 500;
    font-size: .875rem;
    font-family: Roboto,sans-serif;
    border-bottom: 1px solid $foundational-color-16;
  }
  .pro-count-cell, .dashboard-amount {
    justify-content: flex-end;
  }



  .xpo-FilterBar-content button.mat-button {
    height: 100%!important;
    line-height: 32px!important;
    margin-right: 16px!important;
    border-radius: 0px;
    padding: 0 8px;
  }
}